/**
 * Capture UTMs, referrer, etc. and set them in local storage
*/
const storageOlderThan = (storageItem, daysToCheck) => {
  //retrieve storage item as an object
  const itemObj = JSON.parse(localStorage.getItem(storageItem));
  if (itemObj) {
    //See if stored date was more than XX days ago
    let daysAgo = new Date()
    daysAgo.setDate(daysAgo.getDate() - daysToCheck)
    return itemObj.date < daysAgo; //true or false?
  }
  else {
    //storage item does not exist
    return null
  }
};

export const setUpLocalStorage = () => {

  //Parse out parameters we want
  let currentStringStripped = ''
  const currentQuery = new URLSearchParams(window.location.search)

  if (currentQuery.has('gclid')) {
    currentStringStripped += '&gclid=' + currentQuery.get('gclid')
  }
  else if (currentQuery.has('GLCID')) {
    currentStringStripped += '&gclid=' + currentQuery.get('GCLID')
  }
  
  ['source', 'content', 'medium', 'campaign', 'term', 'id'].forEach((type) => {
    const utm = 'utm_' + type
    if (currentQuery.has(utm)) {
      currentStringStripped += '&' + utm + '=' + currentQuery.get(utm)
    }
  });

  //strip leading '&'
  currentStringStripped = currentStringStripped.replace(/^&/, '');

  const current = {
    url: document.location.href,
    query: currentStringStripped,
    date: new Date(),
    referrer: document.referrer
  }

  // Set up localstorage for first touch and last touch
  // First touch needs update if older than 90 days
  if (storageOlderThan('first_touch', 90)) {
    // if last touch is newer than 90 days, replace first_touch with last_touch
    if (!storageOlderThan('last_touch', 90)) {
      localStorage.setItem('first_touch', localStorage.getItem('last_touch'))
    }
    // if no last touch data or last touch data is older than 90 days, set with current data
    else {
      localStorage.setItem('first_touch', JSON.stringify(current))
    }
  }
  // if no first touch data, set it now
  else if (storageOlderThan('first_touch', 90) === null) {
    localStorage.setItem('first_touch', JSON.stringify(current))
  }

  //Set last touch if older than one day
  if (storageOlderThan('last_touch', 1) !== false) {
    localStorage.setItem('last_touch', JSON.stringify(current));
  }

}
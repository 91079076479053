import { getCookie, setCookie } from './auth';

/*
 * Manually create a GTM page view event: we'll use this to track views
 * after the user has loaded/hydrated and is transitioning between pages
 * with in-app routing.
 */
export const populateIframeURL = () => {
    let iframe = document.getElementById('pardot-iframe');
    let frames = '';
    if (iframe) {
        const url = new URLSearchParams(window.location.search);
        ['source', 'content', 'medium', 'campaign'].forEach((type) => {
            if (getCookie(`utm_${type}`) && !url.has(`utm_${type}`)) {
                url.set(`utm_${type}`, getCookie(`utm_${type}`));
            } else if (url.has(`utm_${type}`)) {
                setCookie(`utm_${type}`, url.get(`utm_${type}`));
            }
        });
        if (getCookie('gclid') && !url.has('gclid')) {
            url.set('gclid', getCookie('gclid'));
        } else if (url.has('gclid')) {
            setCookie('gclid', url.get('gclid'));
        }
        iframe.src = iframe.src + '?' + url.toString();
    } else {
    }
};
export const GTMPageView = (url) => {
    const pageEvent = {
        event: 'pageview',
        page: url
    };
    populateIframeURL(url);
    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
};

export function gtag() {
    window && window.dataLayer && window.dataLayer.push(arguments);
}

export function createAuthToken() {
    const authString =
        process.env.HTTP_AUTH_USERNAME + ':' + process.env.HTTP_AUTH_PASSWORD;
    return 'Basic ' + Buffer.from(authString).toString('base64');
}
export function setCookie(name, value, days = 30) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie =
        name + '=' + (value || '') + expires + '; domain=.enfusion.com; path=/';
}
export function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function eraseCookie(name) {
    document.cookie =
        name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
